import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It's finally here. I've been wanting to get the new Gold Card from American Express since it was unveiled. At the time, my credit score wasn't good enough for the Gold Card. After several months of credit gardening, I was instantly approved for the Gold Card. I was able to get the limited-edition Rose Gold version of the card, which is available through referrals until July 17.`}</p>
    <h2>{`First Try`}</h2>
    <p>{`It was this past January when I first applied for the Amex Gold card. As a college student with a `}<a parentName="p" {...{
        "href": "https://credit101.net/score/credit-score-glossary/#fico"
      }}>{`FICO`}</a>{` score of 699, my application instantly denied. Since American Express doesn't have a dedicated reconsideration line, I had to make a reconsideration request with customer service and then wait for the credit analysts to communicate their final decisions `}<strong parentName="p">{`in writing`}</strong>{`. After several unsuccessful attempts, I gave up. Luckily, I already had a Blue Cash Everyday Card from American Express, so my Gold Card application only resulted in a `}<a parentName="p" {...{
        "href": "https://credit101.net/score/credit-score-glossary/#soft-inquiry"
      }}>{`soft pull`}</a>{` on my credit report, which did not hurt my credit score. So, yes, it seems to be true that Amex only does a soft pull when you apply for your second Amex card.`}</p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/credit101.appspot.com/o/images%2Fposts%2Fcards%2Funboxing-the-amex-rose-gold-card%2Fgold_rejection.png?alt=media&token=081608a1-4acf-4bd2-92ca-1e194ab0d3ea",
        "alt": "Amex Gold Application Denied"
      }}></img></p>
    <h2>{`Second Time's the Charm`}</h2>
    <p>{`During my trip to Switzerland last month, I saw an ad from American Express stating that the limited-edition Rose Gold Card is back until 7/17. I'm not a big fan of the Rose Gold color, but I was simply curious if the card will be in Rose Gold when I add it to Apple Pay. And given its rareness, I decided to apply for the Rose Gold edition of the card.`}</p>
    <p>{`My credit score was in the lower range of "good," which would qualify for the Amex Gold. I also had less than five hard inquiries in the past year, let alone that Amex might do a soft pull again. The only problem was that, to get the limited-edition Amex Rose Gold, `}<strong parentName="p">{`you need to be referred by a friend`}</strong>{`.`}</p>
    <p>{`Well, it turns out that your friend doesn't have to have the Gold Card; they can be the cardholder of any of the charge cards from American Express (i.e. Green, Gold, Platinum, and Centurion). Keep in mind that the 50,000 MR points sign-up bonus is still available in select regions when you visit the Amex application website in the Chrome Incognito mode or Safari's Private Browsing mode. A referral link will only get you 40,000 MR points. In other words, you lose 10,000 MR points of signup bonus when you get the Amex Rose Gold.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`The Wait`}</h2>
    <p>{`To my surprise, I was `}<strong parentName="p">{`instantly approved`}</strong>{`. Since I was traveling outside of the US, American Express couldn't ship the card to my foreign address directly, and I had to wait ten days before I could request "replacement card." The card issuer did provide me with a temporary card number for online purchases and mobile wallet payments, so I took advantage of that. Indeed, the Rose Gold Card does show up in the Rose Gold color when added to Apple Wallet.`}</p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/credit101.appspot.com/o/images%2Fposts%2Fcards%2Funboxing-the-amex-rose-gold-card%2Frosegold-apple-pay.jpeg?alt=media&token=3ffbad61-799e-4859-bde9-35b64dda4c88",
        "alt": "Amex Rose Gold Added to Apple Pay"
      }}></img></p>
    <h2>{`Heavy Metal`}</h2>
    <p>{`Because I ordered the card to an international address, the welcome package came in a DHL envelop. It was stuffed inside a white envelope with no American Express branding whatsoever. (Please please please do not throw away any of you mail without knowing what it is.)`}</p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/credit101.appspot.com/o/images%2Fposts%2Fcards%2Funboxing-the-amex-rose-gold-card%2Frosegold-package.jpg?alt=media&token=38c335f0-b261-4a36-905f-28a4f2296bb1",
        "alt": "Rose Gold Welcome Package"
      }}></img></p>
    <p>{`The welcome package is nicely designed. If you pay close attention, the "World Service" watermark seen on Amex's charge cards is also found inside the envelope. But then again, you pay $250 a year to use this card.`}</p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/credit101.appspot.com/o/images%2Fposts%2Fcards%2Funboxing-the-amex-rose-gold-card%2Frosegold_sticker.jpg?alt=media&token=10d6f002-b4c7-478b-919a-58b2dcc118ed",
        "alt": "Rose Gold Card with Confirmation Sticker"
      }}></img></p>
    <p>{`Among the literature inside the welcome package, you can find the piece carrying the Gold Card very easily as it literally is a piece of paper with a piece of metal stuck to it. There's a sticker covering the top part of the Gold Card, reminding you to activate the card before use. When you activate your card, your temporary card number will automatically deactivate. Although, it seems like the temporary card you added to Apple Pay will continue to work.`}</p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/credit101.appspot.com/o/images%2Fposts%2Fcards%2Funboxing-the-amex-rose-gold-card%2Frosegold-weight.jpeg?alt=media&token=ab012df0-82ab-4f6c-8b35-08c0e1be7e51",
        "alt": "Rose Gold Card Weighs 15 grams"
      }}></img></p>
    <p>{`The Amex Rose Gold Card weighs about 15 grams (or 0.53 ounces), which is 2g heavier than most metal cards from Chase (i.e. CSR, CSP, IHG Premier, Amazon). Quite surprisingly, the Gold Card is a gram heavier than the Centurion Card (which I obviously don't have). It's also 3g lighter than the Platinum Card.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Card`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Weight`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JPMorgan Reserve`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`27g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Luxury Card Gold Card`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`22g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`American Express Platinum Card`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`18g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HSBC Premier World Elite`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`17g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Capital One Venture`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`16g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`American Express Gold Card`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`15g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`American Express Centurion Card`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`14g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chase Sapphire Reserve`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`13g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chase Sapphire Preferred`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`13g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chase Amazon Prime`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`13g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wells Fargo Propel Amex`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`10g`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Honestly, the Rose Gold color is not that distinct. In certain lighting conditions, the Rose Gold Card even looks like the Amex Platinum. Looking from the side, you can tell that the Gold Card is constructed out of two sheets of metal instead of one. That's because the RFID tag that enables contactless payment won't work when embedded in one piece of metal. Using two pieces of metal allows the RFID tag to work around the metal sheets. On the other hand, you can see that the Chase Amazon Prime card is made out of a single sheet of metal, hence, it does not support contactless payment.`}</p>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/credit101.appspot.com/o/images%2Fposts%2Fcards%2Funboxing-the-amex-rose-gold-card%2Frosegold-sheets.jpg?alt=media&token=27248071-bde6-41d1-bb81-dfdd32393688",
        "alt": "Amex Gold is constructed out of two sheets of metal"
      }}></img></p>
    <h2>{`What is a Charge Card? What is No-Preset Spending Limit?`}</h2>
    <p>{`The Gold Card from American Express is my first charge card, which comes with no-preset spending limit (or NPSL). Unlike a credit card, a charge card `}<strong parentName="p">{`requires you to pay off your balances in full`}</strong>{` by each due date. Amex does offer the Plan It feature to give you the flexibility of paying off large purchases over several statement periods with interest. NPSL DOES NOT mean you can use the card like crazy; instead, there's an implicit credit line. Each transaction is approved on a case-by-case basis, depending on your credit history.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`The Bottom Line`}</h2>
    <p>{`Getting this card shouldn't be too hard if you have a reliable income and a credit score above 720. `}<strong parentName="p">{`But you have to ask yourself if you can justify that $250 annual fee.`}</strong>{` For me, I will be using all of the $100 airline incidental credit on United Wi-Fi purchases. I will also spend most of the $120 dining credit. So, effectively, the annual fee for me is just $30, which can be easily covered by the 40k signup bonus and the 4x MR points I will earn from grocery shopping and dining out. After all, a metal card is still a credit/charge card; it's the value you get out of it that truly matters.`}</p>
    <blockquote>
      <p parentName="blockquote">{`American Express Gold Card: `}<a parentName="p" {...{
          "href": "http://refer.amex.us/YUNXIWqQ1H?xl=cprm"
        }}>{`Apply Here`}</a></p>
    </blockquote>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      